<template>
  <div>
    <v-container>
      <page-title title="Division">
        <template slot="action">
          <v-btn class="mx-1" @click="$router.go(-1)">Back</v-btn>
          <v-btn class="mr-n1 mx-1" @click="saveData()" color="primary">Save</v-btn>
        </template>
      </page-title>

      <card-expansion title="General">
        <v-form v-model="formValid" lazy-validation ref="form1">
          <v-row>
            <v-col cols="12" md="6" lg="6">
              <v-text-field v-model="formData.div_code" :rules="formRules.div_code" class="" label="Div Code" placeholder="Div Code" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-text-field v-model="formData.div_name" :rules="formRules.div_name" class="" label="Div Name" placeholder="Div Name" outlined dense></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </card-expansion>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {

  },
  data() {
    return {
      formValid: null,
      formData: {
        div_code: null,
        div_name: null,
      },
      formRules: {
        div_code: [v => !!v || 'Div Code is required'],
        div_name: [v => !!v || 'Div Name is required'],
      },
    }
  },

  methods: {
    async saveData() {
      this.$refs.form1.validate();

      if (!this.formValid) {
        this.showAlert("error", "Oops.. Please complete the form");
        return;
      }

      let formData = new FormData();

      formData.append('div_code', this.formData.div_code);
      formData.append('div_name', this.formData.div_name);

      await this.$axios
        .post("division/save", formData)
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            this.goBack();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    }
  },

  mounted() {
  }
}
</script>